import React from 'react';
import KeyPoint from '../../components/key-point';
import ArticleLayout from '../../layouts/article';

export default () => (
  <ArticleLayout category="document" title="技术栈" subtitle="我们常用的技能组合">
    <KeyPoint index="○" title="Web 前端" content="React.js / Remax.js / TypeScript / Node.js" />
    <KeyPoint index="○" title="App 前端" content="Flutter / Jetpack Compose / SwiftUI" />
    <KeyPoint index="○" title="后端" content="Java / Spring Framework / JPA" />
    <KeyPoint index="○" title="运维" content="Kubernetes / Service Mesh / Docker" />
    <KeyPoint index="○" title="架构" content="Domain-driven Design / MicroService" />
  </ArticleLayout>
);
